.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.group {
  background-color: #0d7077;
  width: 210px;
  z-index: 11;
}

.item {
  border-width: 0px;
  border-bottom-width: 1px;
  padding-left: 0px;
}

.item:hover {
  background-color: #ffff;
  opacity: 0.3;
}

.btn {
  width: fit-content;
}
