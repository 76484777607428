.language-dropdown-toggle {
  
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #e4e4e6;
  font-weight: bold;
  padding: 6px;
  color: black;
  text-transform: uppercase;
  font-family: 'Sifonn';
}
.language-dropdown {
  position: relative;
  z-index: 100 !important;
  margin: 7px;
  margin-right: 20px;
  text-align: center;
  font-family: 'Sifonn'!important;

}

.language-item, .language-selected {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 50%;
  border: 3px solid #0d7077 !important; //E4E4E6
  font-weight: 700;
  color: #0d7077 ;
  background-color: #faf8f8 !important;
  margin: 4px 0px !important;
  text-transform: uppercase;
}

.language-item:hover, .language-selected{
    background-color: #0d7077 !important; 
    color: white !important;
}

.language-menu {
  background-color: transparent;
  border: 0px;
  transform: translate(0px, 55px) !important;
  max-width: 50px !important;
  min-width: 40px !important;
}

@media screen and (max-width: 567px) {
  .language-menu {
    transform: translate(0px, 45px) !important;
  }
}
