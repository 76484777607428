// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

// /* width */
// ::-webkit-scrollbar {
//   width: 4px;
//   height: 4px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
