.tab-content {
  height: 100%;
  padding-bottom: 50px;
  padding-top: 20px;
}

.upload-placeholder,
.upload-image,
.upload-image-question,
.upload-image-question-placeholder {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 140px;
  height: 140px;
  z-index: 1000;
}
.upload-placeholder:hover,
.upload-image-question-placeholder:hover {
  cursor: pointer;
  border: 3px solid #fff;
  z-index: 1000;
}
.upload-image-question,
.upload-image-question-placeholder {
  width: 160px;
  height: 90px;
}

.description,
.table-values {
  max-width: 200px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-values {
  max-width: 100px;
}

.table td {
  vertical-align: middle;
  border-left: solid 1px #e0e0e0;
}

.table thead {
  background-color: #efefef;
}

table {
  border-collapse: collapse;
  width: auto;
}

.custom-popup {
  min-width: 300px;
  height: auto;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  width: 100px;
  margin: 10px;
  text-align: center;
}

.separator {
  height: 1px;
  color: #0d7077;
}

.separator-thick {
  color: #0d7077;
  //background-color: #0d7077;
  border-color: #0d7077;
  height: 1px;
  //opacity: 0.7;
}

.sidebar-list-item {
  border-width: 0px;
  border-bottom-width: 1px;
  padding: 0px;
}

.sidebar-list-item:hover {
  background-color: #6a9494;
}

.sidebar-list-header {
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #e0e0e0;
  padding-left: 10px;
  height: 48px;
}

.sidebar-list-footer {
  border-width: 0px;
  border-top-width: 2px;
  border-color: #e0e0e0;
  padding-left: 10px;
}

.sidebar-footer-name-btn {
  max-width: 130px;
}

.settings-list-header {
  background-color: #0d7077;
  color: #ffff;
  font-weight: bold;
}

audio::-webkit-media-controls-panel {
  background-color: #ffff;
  border-color: #ffff;
}

audio:focus {
  outline: none;
}

.mapboxgl-popup-content {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0d7077;
}

.route-image {
  object-fit: cover;
  width: 150px;
  height: 150px;
}

.routes-default-route {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.routes-default-select {
  width: 300px;
  margin-right: 40px;
}
.routes-default-label {
  margin: 0px 10px;
  font-weight: bold;
  color: #0d7077;
}

.route-objects-modal {
  position: absolute;
  height: 100% !important;
  padding: 50px 0px !important;
  left: 30% !important;
}

.MuiTypography-body1 {
  font-family: 'Sifonn' !important;
}

.settings-collapse {
  height: 95%;
}

@media screen and (max-height: 400px) and (orientation: landscape) {
  #map .mapboxgl-ctrl-top-right {
    display: none;
  }
}
