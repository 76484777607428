// Here you can add other styles

@import 'text.scss';
@import 'sidebar';
@import 'buttons';
@import 'quill';
@import 'content';
@import 'object-content';
@import 'minigame';
@import 'languageSelection';
@import 'home';
@import '../views/Map/MapInfo/MapInfoDescription.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Karma:300,400,500,600,700&subset=latin-ext');
/* sifonn */
@font-face {
  font-family: 'Sifonn';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Sifonn-Basic.eot'); /* IE9 Compat Modes */
  src: local(''),
    //  url('../assets/fonts/Sifon-Basic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/Sifonn-Basic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Sifonn-Basic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Sifonn-Basic.svg#Sifonn')
      format('svg'); /* Legacy iOS */
}
html,
body,
#root,
.c-app,
.c-wrapper,
.c-body,
.c-main,
.container-fluid {
  height: 100%;
  min-height: 0;
  overflow: hidden;
}


.c-app,
.c-wrapper{
  overflow: visible !important;
}

.c-header-fixed.c-header-fixed {
  position: static !important;
  z-index: auto !important;
}

html,
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body{
  -webkit-overflow-scrolling: touch;
}

*,
input,
select,
textarea,
option,
button {
  outline: none !important;
}

#map, .modal-container {
  font-family: 'Karma', 'Open Sans', sans-serif;
  .kaunastic-title {
    font-family: 'Sifonn';
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .makeStyles-logo-1 {
    width: 43px!important;
    height: 100%;
    margin: 0;
    transform: translate(-50%, 0);
  }
  #map-actions {
    bottom: 30px !important;
  }
  #filters,
  .minigame-paper {
    max-height: 200px;
    .MuiDialogTitle-root {
      padding: 8px 24px;
    }
  }
  #routes {
    max-height: 230px;
    overflow: hidden;
    .MuiDialogTitle-root {
      padding: 8px 24px;
    }
    .MuiGrid-grid-xs-12 {
      max-width: 50%;
      flex-basis: 50%;
    }
    .MuiCard-root {
      width: 100px;
      .MuiCardMedia-root {
        height: 100px;
      }
    }
  }
}
