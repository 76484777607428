.text {
  color: #ffffff;
}

.btn-text {
  color: #0d7077;
}

h3 {
  color: #0d7077;
}

.list-item-text,
.list-header,
.list-footer {
  color: #ffffff !important;
  text-align: left;
}

.list-header {
  font-weight: bold;
}

.list-footer {
  font-weight: bold;
  font-size: 12px;
}

.image-text{
  position: absolute;
  background-color: #ffffff;
  bottom: 0px;
  right: 0px;
  opacity: 0.5;
  border-top-left-radius: 20px;
  padding: 0px 20px;
}

.style-manager-checkbox{
  color:  #0d7077;
}
