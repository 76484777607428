.react-responsive-modal-overlay {
  z-index: 15 !important;
}

.modal-container,
.modal-container-no-images {
  display: flex;

  max-width: 1344px !important; // 70% at 1920px
  width: 100% !important;
  @media (min-width: 1200px) {
    width: 70% !important;
  }

  min-height: 50% !important;

  // height: auto;
  // flex-direction: row;

  margin: 10px;
  border-radius: 20px;

  .object-content {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .object-content-description {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    height: 100%;
    max-width: 600px;
    min-width: 300px;
    width: 100%;

    .object-content-description-div,
    .object-content-description-phoneAndPage {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > a {
        color: black;
      }
    }

    .object-content-description-phoneAndPage {
      display: block;

      & > a {
        padding-right: 50px;
      }
    }

    .object-content-description-separator {
      align-self: center;
      width: 100%;
    }
    .object-content-description-header {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .object-content-description-title {
      color: #0d7077;
      font-size: 30px;
      overflow-wrap: break-word;
      width: 90%;
      text-transform: uppercase;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Sifonn';
    }
    .content-description-description {
      max-height: 40vh;
      padding-bottom: 10px;
      flex-grow: 1;
      * {
        font-family: 'Karma';
      }

      // Ugly fix for perfect-scrollbar race
      .ps__rail-y {
        left: auto !important;
      }

      .ql-editor{
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        resize: none;
        .ql-editor video {
          pointer-events: all;
        }
      }
    }
    .content-description-description::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
    .description-media {
      width: 100%;
      text-align: center;
    }
    .content-description-separator-thick {
      color: #0d7077;
      background-color: #0d7077;
      border-color: #0d7077;
      height: 1px;
      opacity: 0.3;
      margin: 10px -17px;
    }
  }

  .object-content-carousel {
    width: 100%;

    .carousel,
    .carousel .carousel-inner,
    .carousel .carousel-inner .carousel-item {
      height: 100%;
    }

    .carousel-indicators li {
      background-color: transparent;
      border: white solid 3px;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin: 0px 5px;
    }
    .carousel-indicators .active {
      background-color: white;
      height: 20px;
      width: 20px;
      border: 0px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      // @media (max-width: 1199.98px) {
      //   max-height: 225px;
      // }
    }
  }
}

.modal-container-no-images {
  max-width: 600px !important;
}

@media (max-width: 1199.98px) {
  .modal-container-no-images{
    margin-top: 50px !important;
  }
  .modal-container {
    margin-top: 50px !important;
    max-width: 400px !important;

    .object-content {
      flex-direction: column;
      width: 100%;
    }
    .object-content-description {
      margin: 0px;
      padding: 15px 15px 0px 15px;

      .content-description-description {
        max-height: 230px;
        padding-bottom: 0px;
      }
    }
    .object-content-description-div,
    .object-content-description-phoneAndPage {
      flex-direction: column !important;
    }
    .object-content-description-phoneAndPage {
      margin-bottom: 0px !important;
    }
    .object-content-description-phoneAndPage > a {
      margin-bottom: 10px !important;
    }
    .object-content-description-header {
      width: 100%;

      display: flex;
      flex-direction: row;
    }
    .object-content-description-title {
      font-size: 25px;
      overflow-wrap: break-word;
      width: 90%;
    }

    .object-content-description-socialMedia {
      display: flex;
      width: fit-content;
      padding-bottom: 10px !important;
    }
    .object-content-description-socialMedia > a {
      margin-top: 10px !important;
      color: black;
    }
    .description-media {
      margin-bottom: 10px;
    }

    .image-text {
      font-size: 10px;
    }

    .carousel-indicators {
      margin-bottom: 10px !important;
    }

    .carousel-indicators li {
      border-width: 2px !important;
      width: 8px !important;
      height: 8px !important;
    }

    .carousel-indicators .active {
      width: 12px !important;
      height: 12px !important;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 15px !important;
      height: 15px !important;
    }
  }
}
