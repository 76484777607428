.main-marker {
  background-image: url('../assets/icons/marker.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 37.5px;
  height: 59px;
}

.marker {
  background-image: url('../assets/icons/marker_flag.png');
  width: 68px;
  height: 106px;
}

.welcome__close{
  position: absolute !important;
  z-index: 10;
  top: 2px;
  right: 2px;
}

.minigame-paper {
  @media screen and (max-width: 767px) and (orientation: portrait) {
    background-size: 50px !important;
  }
  min-width: 330px;
  max-width: 1112px;
  overflow: auto;
  background-image: url('../assets/images/kaunastic-game-bg.png');
}
.minigame-content {
  @media screen and (max-width: 767px) and (orientation: portrait) {
    padding: 1rem;
    padding-top: 1rem;
    margin-bottom: 3rem;
  }
  position: absolute;
  bottom: 0;
  padding: 3rem;
  width: 100%;
  left: 0;
  .minigame-title-text {
    @media screen and (max-width: 767px) and (orientation: portrait) {
      padding-bottom: 2rem;
    }
    @media screen and (max-width: 360px) and (orientation: portrait) {
      font-size: 2.5rem;
      line-height: 2.5rem;
      width: 70%;
    }
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      width: 80%;
    }
    @media screen and (max-height: 350px) and (orientation: landscape) {
      font-size: 2.5rem;
      line-height: 2.5rem;
      width: 70%;
    }
    font-family: 'Sifonn';
    font-size: 4rem;
    line-height: 4rem;
    padding: 20px 0;
  }
  .minigame-description {
    @media screen and (max-width: 767px) and (orientation: portrait) {
      max-width: 100%;
    }
    font-weight: bold;
    color: #0d7077;
    max-width: 70%;
    font-size: 15px;
    .minigame-distance {
      color: #d73253;
      font-size: 20px;
      padding-bottom: 1rem;
    }

    .minigame-button,
    .minigame-rules-button {
      font-family: 'Sifonn';
      width: fit-content;
      background-color: #000000;
      text-align: center;
      margin-top: 2rem;
      text-transform: uppercase;
      margin-right: 10px;
      .minigame-button-text {
        color: white;
        display: flex;
        vertical-align: middle;
        text-align: center;
      }
    }
    .minigame-rules-button {
      background-color: #0d7077;
    }
  }
}

.minigame-description-title {
  position: relative;
  background-color: #afdce6;
  font-weight: bold;
  color: #ffff;
  padding: 0px !important;
}

.minigame-img {
  z-index: 2;
  height: 124px;
  width: 310px;
  position: absolute;
  bottom: 35px;
  right: 18px;
  background-image: url('../assets/icons/Kaunastic_marsrutas_6.png');
  background-size: contain;
  background-repeat: no-repeat;
  .minigame-img-text {
    font-family: 'Sifonn';
    margin-top: 10px;
    margin-left: 10px;
    font-size: 25px;
    width: 80%;
  }
}

.go-title-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 32px;
  font-weight: bold;
  width: 50%;
  line-height: 100%;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #788fb9;
}

.go-title-text {
  position: absolute;
  top: 278px;
  left: 4rem;
  line-height: 100%;
  font-size: 6rem;
  color: black !important;
  font-family: 'Sifonn';
  width: 50%;
}

.minigame-logo {
  object-fit: cover;
  width: 100%;
  height: 198px;
}

.minigame-start-btn {
  background-color: #0d7077;
  width: 100%;
}
.minigame-text {
  font-size: 14px;
  width: 90%;
}
.minigame-bold-text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}
.minigame-btn-div {
  text-align: center;
  margin-top: 15px;
}

.sb-board {
  position: absolute;
  height: 100%;
  z-index: 4;
  padding: 0px !important;

  @media screen and (max-width: 767px) and (orientation: portrait) {
    width: 100%;
    max-height: 70%;
    height: auto;
  }
  @media screen and (max-width: 1279px) and (orientation: landscape) {
    height: auto;
  }

  .MuiDialogContent-root {
    padding: 0px !important;
  }
  .sb-paper {
    width: 450px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      width: 300px;
    }
    height: 100%;
    display: flex;
    flex-flow: column-reverse;

    .sb-head {
      font-family: 'Sifonn';
      position: relative;
      height: 160px;

      @media screen and (max-width: 767px) and (orientation: portrait) {
        height: 100px;
        background-size: cover;
        background-position-x: 20px;
        background-position-y: -80px;
      }
      @media screen and (max-width: 1279px) and (orientation: landscape) {
        height: 80px;
        background-size: cover;
        background-position-x: 20px;
        background-position-y: -80px;
      }
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: -80px;
      background-image: url('../assets/icons/tu_esi_kaunastic.png');
      background-color: #afdce6;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sb-head-title {
        margin-top: 5px;
        margin-left: 20px;
        width: 70%;
        line-height: 40px;
        font-size: 2.5rem;
        @media screen and (max-width: 767px) and (orientation: portrait) {
          text-align: center;
          width: 100%;
          font-size: 1.5rem;
          margin-top: 5px;
          margin-left: 0px;
        }
        @media screen and (max-width: 1279px) and (orientation: landscape) {
          text-align: center;
          width: 100%;
          font-size: 1.2rem;
          margin-top: -10px;
          margin-left: -10px;
        }
      }
      .sb-head-body {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .info {
          @media screen and (max-width: 767px) and (orientation: portrait) {
            width: 120px;
          }
          @media screen and (max-width: 1279px) and (orientation: landscape) {
            width: 120px;
          }
          background-color: white;
          width: 150px;
          height: 100%;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          -webkit-box-shadow: 0px -2.02732px 2.70309px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px -2.02732px 2.70309px rgba(0, 0, 0, 0.25);
          box-shadow: 0px -2.02732px 2.70309px rgba(0, 0, 0, 0.25);

          .info-content,
          .info-content-label {
            font-family: 'Karma';
            display: flex;
            flex-direction: row;
            justify-content: center;
            @media screen and (max-width: 1279px) {
              font-size: 14px;
            }
            .sb-numberSquare {
              @media screen and (max-width: 1279px) {
                height: 28px;
                width: 23px;
              }
              font-size: 18px;
              height: 40px;
              width: 30px;
              margin-bottom: -5px;
              border-width: 1px;
              border-style: solid;
              border-radius: 3px;
              border-color: #dddddd;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .info-content-label {
            margin-top: 5px;
          }
        }
      }
    }

    .sb-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 10px;
      .sb-text {
        font-size: 14px;
        padding: 5px 0px;
      }
    }
  }
}

.btn-and-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sb-message {
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      height: 30px;
      width: 50%;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
      height: 50px;
      width: 70%;
    }
    width: 60%;
    font-family: 'Sifonn';
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding-top: 5px;
    padding-right: 10px;
    text-align: center;
    border: 3px solid #0d7077;
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .sb-message-text {
      @media screen and (max-width: 1279px) and (orientation: landscape) {
        font-size: 18px;
        margin-left: 10px;
        margin-top: -5px;
        overflow-y: scroll;
        height: 20px;
      }
      font-weight: bold;
      font-size: 28px;
      margin-left: 10px;
      margin-top: 5px;
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #788fb9;
    }
    .sb-icon {
      @media screen and (max-width: 1279px) and (orientation: landscape) {
        max-width: 30px;
      }
      max-width: 80px;
    }
  }
  .btn {
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      height: 30px;
      width: 70%;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
      width: 90%;
    }
    height: 45px;
    width: 80%;
    background-color: #000000;
    text-align: center;
    text-transform: uppercase;
    font-weight: lighter !important;

    .btn-text {
      @media screen and (max-width: 1279px) and (orientation: landscape) {
        font-size: 10px;
      }
      font-family: 'Sifonn';
      font-weight: lighter !important;
      font-size: 19px;
      color: white;
    }
  }

  @media screen and (max-width: 767px) and (orientation: portrait) {
    position: absolute;
    bottom: 10px;
    z-index: 10;
    width: 100%;
  }
  @media screen and (max-width: 1279px) and (orientation: landscape) {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    width: 50%;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .MuiDialogTitle-root {
    padding-bottom: 10px !important;
  }
}

@media screen and (max-width: 1279px) and (orientation: landscape) {
  .MuiDialogTitle-root {
    padding-bottom: 2px !important;
    padding-top: 2px !important;
  }
}

.q-image {
  object-fit: cover;
  width: 300px;
  height: 169px;
  border: 3px solid #0d7077;
  @media screen and (max-width: 767px) and (orientation: portrait) {
    width: 250px;
    height: 141px;
  }
  @media screen and (max-width: 1279px) and (orientation: landscape) {
    width: 180px;
    height: 101px;
  }
}
.q-info {
  margin: 0px 20px;
  overflow-y: unset !important;

  overflow-y: unset !important;
  text-justify: inter-word;
  text-align: justify;
  .q-count {
    color: #0d7077;
    font-weight: bold;
    text-align: center;
    font-size: 2.5rem;
    height: auto !important;
    @media screen and (max-width: 767px) and (orientation: portrait) {
      font-size: 20px;
    }
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      font-size: 17px;
    }
  }
  .q-text {
    font-family: 'Karma';
    text-justify: inter-word;
    text-align: justify;
    margin-top: 40px;
    font-size: 1rem;
    @media screen and (max-width: 767px) and (orientation: portrait) {
      margin-top: 5px;
      font-size: 0.8rem;
      max-height: 100px;
      overflow-y: scroll;
    }
    @media screen and (max-width: 1279px) and (orientation: landscape) {
      margin-top: 8px !important;
      margin-bottom: -10px;
      font-size: 0.7rem;
      line-height: 13px;
      margin-top: 0px;
    }
  }
}
@media screen and (max-width: 1279px) and (orientation: landscape) {
  .sb-xButton {
    top: 2px;
    left: 265px;
  }
}
.sb-xButton,
.go-xButton {
  position: absolute !important;
  z-index: 10;
  top: 2px;
  right: 2px;
  border: 3px solid white !important;
  background-color: #0d7077 !important;
  color: white !important;
}
.go-xButton {
  top: 7px;
  right: 7px;
}
.sb-xButton:hover,
.go-xButton:hover {
  background-color: #094549;
}

.sb-caret-down,
.sb-caret-up {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #0d7077;
}

.sb-caret-down:hover,
.sb-caret-up:hover {
  cursor: pointer;
}

.sb-button,
.go-tryAgainBtn {
  background-color: #0d7077;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.go-tryAgainBtn {
  background-color: black;
  color: white;
  width: fit-content;
  height: 58px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: 'Sifonn';
  margin-left: 3rem;
}

.sb-mobile-nextBtn {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
}

.go-board {
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.go-paper {
  width: 690px;
  height: 460px;
  background-repeat: no-repeat;
  border-radius: 30px !important;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25) !important;
}
.go-text {
  font-size: 14px;
  margin-bottom: 5px;
}

.go-message {
  border-radius: 3px;
  background-color: #7bc950;
  padding: 8px;
  color: #ffff;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
}
.go-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.go-title {
  background-color: #f34213;
  font-weight: bold;
  padding: 50px;
  color: #ffff;
  text-align: center;
}

.go-results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #0d7077;
  font-weight: bold;
  font-size: 18px;
  margin-top: 16px;
}

.go-end-message {
  color: #0d7077;
  font-weight: bold;
  font-size: 20px;
}

.q-collapse-text {
  font-size: 10px;
  opacity: 0.5;
}

.carousel-indicators li {
  background-color: transparent;
  border: white solid 3px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 0px 5px;
}
.carousel-indicators .active {
  background-color: white;
  height: 20px;
  width: 20px;
  border: 0px;
}

@media screen and (max-width: 576px) {
  .sb-button {
    width: 200px !important;
  }
  .minigame-paper {
    min-width: 280px;
    width: 100% !important;
    //max-width: 375px;
    //padding: 0px 15px;
    overflow: auto;
  }

  .minigame-logo {
    height: 158px;
  }

  .minigame-title-text {
    top: 90px;
    font-size: 25px;
    width: 70%;
  }

  .sb-message-text {
    margin-left: 15px !important;
    font-size: 25px !important;
  }

  .sb-board {
    left: 50%;
    transform: translate(-50%, 0);
  }

  .go-board {
    top: -5%;
    transform: translate(-50%, 50%);
  }

  //.sb-paper,
  .go-paper {
    width: 100%;
  }

  .sb-button {
    width: 300px;
  }

  .q-image {
    height: 142px;
  }

  .carousel-indicators {
    margin-bottom: 10px !important;
  }

  .carousel-indicators li {
    border-width: 2px;
    width: 8px !important;
    height: 8px !important;
  }

  .carousel-indicators .active {
    width: 12px !important;
    height: 12px !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 15px !important;
    height: 15px !important;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .go-paper {
    border-radius: 0 !important;
  }
  .go-board {
    width: 100%;
    height: 100%;
    border-radius: 0;
    min-height: 100%;
    overflow: auto;
    top: 0;
    transform: translate(0, 0);
    left: 0;
  }
  .go-paper {
    height: 100%;
  }
  .go-image {
    object-position: right;
    height: 390px;
  }
  .go-title-text {
    font-size: 4rem;
    left: 2rem;
  }
  .go-tryAgainBtn {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .go-paper {
    border-radius: 0 !important;
  }
  .go-board {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .go-paper {
    width: 100%;
  }
  .minigame-content {
    padding-bottom: 1.5rem;
    width: 80%;
    left: 40%;
    .minigame-text {
      font-size: 12px;
    }
  }
  .go-image {
    object-position: right;
    width: 40%;
    height: 100vh;
  }
  .go-title-text {
    font-size: 4rem;
    left: auto;
    top: 4rem;
    left: 40%;
  }
  .go-tryAgainBtn {
    margin-left: 40%;
  }
}
