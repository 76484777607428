.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 50px;
  height: 15rem;
}

.ql-editor iframe,
.ql-editor video {
  pointer-events: none;
}

.ql-tooltip {
  left: 0 !important;
}

.mapObject-description iframe {
  max-width: 500px;
}

#editor-container {
  height: 375px;
}

.ql-container {
  font-family: 'Karma';
  font-size: 18px;
}

.ql-editor img {
  max-width: 560px !important;
}
.ql-editor {
  max-width: 560px;
  overflow-x: hidden;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}
