.basic-btn {
  background-color: #0d7077;
  border-radius: 0%;
  font-family: Segoe UI;
}
.basic-btn:hover {
  cursor: pointer;
}

.action-btn {
  border-color: #0d7077;
  background-color: #ffffff;
  color: #0d7077;
  padding: 8px;
  height: 38px;
  align-content: center;
}

.action-btn:hover {
  background-color: #0d7077;
  border-color: #0d7077;
  color: #ffffff;
}

// Image selector
.delete-btn {
  position: absolute;
  bottom: 55px;
  z-index: 1002;
  left: 115px;
}
.delete-btn:hover,
.delete-icon-btn:hover {
  cursor: pointer;
  opacity: 0.7;
  z-index: 1002;
}
.edit-btn {
  position: absolute;
  bottom: 55px;
  z-index: 1002;
  left: 5px;
}
.edit-btn:hover {
  cursor: pointer;
  opacity: 0.7;
  z-index: 1002;
}

.add-icon,
.add-audio,
.route-objects {
  color: #0d7077;
  border: 1px solid #0d7077;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #ffff;
  padding: 5px 30px 5px 30px;
}
.add-icon:hover,
.add-audio:hover,
.route-objects:hover {
  cursor: pointer;
  background-color: #0d7077;
  border-color: #0d7077;
  color: #ffffff;
  z-index: 1002;
}
.add-icon {
  margin-left: 15px;
}

.accordeon-label:hover {
  color: #0d7077;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 200;
  border-radius: 50%;
}

.sidebar-list-item-button {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.xButton{
  position: absolute;
  z-index: 10;
  top: -10;
  right: -10;
  border: 3px solid white;
  background: #0d7077;
  color: white;
}
.xButton:hover{
  background: #094e53
}

.object-content-btn{
  color: #0d7077;
  cursor: pointer;
}
.object-content-btn:hover{
  opacity: 0.8;
}

.ap-slide-btn{
  position: absolute;
  z-index: 10;
  background-color: #0d7077;
  color: #ffffff;
  border: 1px solid #e0e0e0;
  border-left: 0px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 45%;
  border-bottom-right-radius: 45%;
  height: 47px;
  width: 60px;
}